import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
// import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
// import gsap from "gsap";
// import { motion } from "framer-motion";
import parse from "html-react-parser";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useGeolocated } from "react-geolocated";
import { BiCurrentLocation } from "react-icons/bi";
import InputField from "../components/inputField";
// import { Card } from "react-bootstrap";
// import ContactUsPage from "./contact-us";
// import { MultiSelect } from "react-multi-select-component";

// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import PropertyCard from "../components/property-card";
import AnimatedImageCard from "../components/animated-image-card";
import ContactFormHome from "../components/contact-form-home";
import CartContext from "../context/CartContext";

import ContactFormMorePeople from "../components/contact-form-more-people";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";
import ExpertCard from "../components/expert-card";
import NoLinkPropertyCard from "../components/no-link-property-card";
import BasicLayout from "../components/basic-layout";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const OfficesInBankPage = ({
	data: {
		popLocation,
		heroInterior,
		paddington,
		canaryWharf,
		piccadilly,
		westminster,
		site,
		bottomImage1,

		allAirtableProperties,
	},
}) => {
	const tl = useRef();

	function round5(x) {
		return Math.ceil(x / 5) * 5;
	}
	const {
		cart,
		settingVal,
		viewport,
		onLocationChange,
		filteredOffices,
		getDistanceFromLatLonInKm,
		distancePoint,
		OnRemoveFromCart,
		OnAddToCart,
		onLocationChangeScroll,
		onLocationChangePan,
		shortOffices,
		sortedBy,
		capacityMinMax,
	} = React.useContext(CartContext);

	const siteUrl = site.siteMetadata.siteUrl;
	// const pluginImage = getImage(heroImg.childImageSharp.gatsbyImageData);

	const [showForm, setShowForm] = useState(false);
	const [officeByDistance, setOfficeByDistance] = useState([]);
	const [officeByPrice, setOfficeByPrice] = useState([]);
	const [officeByPriceHigh, setOfficeByPriceHigh] = useState([]);
	const [officeAmount, setOfficeAmount] = useState(5);
	const [mapChecked, setMapChecked] = useState(false);
	const [mapMobileChecked, setMapMobileChecked] = useState(false);

	const defaultImage = bottomImage1;

	// Check if a featured image is available and set it initially
	const initialImage = popLocation.popularLocationsFields.featuredImage
		? popLocation.popularLocationsFields.featuredImage.localFile
		: defaultImage;

	const [bottomImage, setBottomImage] = useState(initialImage);

	var floors = [];

	useEffect(() => {
		if (typeof Window !== "undefined") {
			onLocationChange(
				popLocation.popularLocationsFields.latitude,
				popLocation.popularLocationsFields.longitude
			);
			settingVal(
				popLocation.popularLocationsFields.locationName,
				"locationValue"
			);
			settingVal("Office Type", "selectedOffice");
			settingVal({ min: 0, max: 75, reset: true }, "numberOfPeople");
			settingVal(
				{
					min: 0,
					max: 150000,
					minPerDesk: 0,
					maxPerDesk: 2000,
					perDesk: false,
				},
				"budgetPerDesk"
			);
			settingVal("Sort", "sortedBy");
			settingVal(10, "distanceAmount");
			settingVal([], "selectedFeatures");
		}
	}, []);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	// const onPinClick = (pin, active) => {
	// 	// console.log("pin", pin, "activePopup", activePopup);

	// 	setActivePopup(pin);

	// 	setShowPopup(true);
	// };

	const OnMapToggleClick = () => {
		if (mapChecked) {
			var temp = !mapChecked;
			setOfficeAmount(officeAmount + 5);
			setMapChecked(temp);
		}
		if (!mapChecked) {
			var temp = !mapChecked;
			setMapChecked(temp);
		}
	};
	const OnMapMobileToggleClick = () => {
		if (mapMobileChecked) {
			var temp = !mapMobileChecked;

			setMapMobileChecked(temp);
		}
		if (!mapMobileChecked) {
			var temp = !mapMobileChecked;
			setMapMobileChecked(temp);
		}
	};

	const mapRef = useRef();
	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	useEffect(() => {
		const newVewport = {
			...viewport,
			latitude: viewport.latitude,
			longitude: viewport.longitude,
		};

		// onLocationChange(viewport.latitude, viewport.longitude);
		// console.log(selectedFeatures);
	}, []);

	const [modalShow, setModalShow] = React.useState(false);
	const [modalShowDesktop, setModalShowDesktop] = React.useState(false);
	const [modalProperty, setModalProperty] = React.useState("");

	useEffect(() => {
		if (modalProperty?.node?.floorsAvailable !== null) {
			floors =
				modalProperty?.node?.floorsAvailable
					?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
					?.slice() || [];
		}
	}, [modalProperty]);

	useEffect(() => {
		// Check if filteredOffices is defined before sorting
		const sortedFilteredOffices =
			filteredOffices?.length > 0
				? [...filteredOffices].sort((a, b) => {
						const distA = getDistanceFromLatLonInKm(
							distancePoint.latitude,
							distancePoint.longitude,
							a.node.locationLatitude,
							a.node.locationLongitude
						);
						const distB = getDistanceFromLatLonInKm(
							distancePoint.latitude,
							distancePoint.longitude,
							b.node.locationLatitude,
							b.node.locationLongitude
						);
						return distA - distB;
				  })
				: [];

		// Check if popLocation and its fields are defined
		const popularLocationsFields = popLocation?.popularLocationsFields;
		const featuredProperties = popularLocationsFields
			? [
					popularLocationsFields.property1,
					popularLocationsFields.property2,
					popularLocationsFields.property3,
					popularLocationsFields.property4,
					popularLocationsFields.property5,
			  ]
					.map((id) =>
						allAirtableProperties?.edges.find(
							(prop) => prop.node.fixedId === id
						)
					)
					.filter((prop) => prop)
			: [];

		// Function to check if an office is already included
		const isAlreadyAdded = (office) =>
			featuredProperties.some((o) => o.node.fixedId === office.node.fixedId);

		// Filter out offices that are not already in featuredProperties and take enough to make up to 20
		const newOffices = sortedFilteredOffices
			?.filter((office) => !isAlreadyAdded(office))
			.slice(0, 24 - featuredProperties.length);

		// Combine initial and new offices
		const combinedOffices = [...featuredProperties, ...newOffices];

		// Update state and settingVal with the combined list
		settingVal(combinedOffices, "shortOffices");
	}, [
		filteredOffices,
		allAirtableProperties,
		popLocation?.popularLocationsFields,
		distancePoint,
	]);

	return (
		<>
			<GatsbySeo
				title={popLocation.seoFields?.metaTitle}
				language="en"
				noindex
			/>

			<div>
				<BasicLayout>
					{/* <hr className="text-med-grey py-1 my-0" /> */}

					<section className=" pt-4 pb-5 ">
						<Container>
							{mapChecked === false && (
								<Row id="content" className="h-100 d-none d-xl-flex">
									<Col>
										{/* <Filters /> */}
										<Row
											className="position-relative mt-0  py-0 mb-0"
											style={{ index: 1 }}
										>
											{showForm === true && (
												<Col xs={12} lg={6}>
													<div
														style={{
															borderRadius: "10px",
															boxShadow: "0px 1px 20px #00000029",
														}}
														className="py-3 py-md-4 px-3 px-md-5  mb-md-5 mb-3 bg-primary bg-opacity-10 "
													>
														<Row>
															<Col>
																<h2 className="fs-2">
																	Need more than 75 desks?
																</h2>
																<p>
																	Fill in your contact details and one of our
																	agents will be in touch to discuss the options
																	we have available.
																</p>
																<ContactFormMorePeople />
															</Col>
														</Row>
													</div>
												</Col>
											)}
											{shortOffices?.map((property, i, a) => (
												<Col className="mb-5" xs={12} md={6} xl={4}>
													{" "}
													<NoLinkPropertyCard property={property} index={i} />
												</Col>
											))}
											{shortOffices?.length < 1 && (
												<Col>
													<h2 className="fs-3 mt-4">No matches</h2>
													<p>
														Try changing some of your filters or adjusting your
														search radius.
													</p>
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							)}
							{mapMobileChecked === false && (
								<Row id="content" className="h-100 d-xl-none mt-4">
									<Col>
										{/* <Filters /> */}

										<Row
											className="position-relative mt-0 py-0 mb-0"
											style={{ index: 1 }}
										>
											{shortOffices?.map((property, i, a) => (
												<Col className="mb-5" xs={12} md={6} lg={6}>
													{" "}
													<NoLinkPropertyCard property={property} index={i} />
												</Col>
											))}
										</Row>
									</Col>
								</Row>
							)}
						</Container>
					</section>
					<section className="py-5">
						<Container>
							<Row className="g-5 align-items-center">
								<Col lg={4}>
									<div style={{ borderRadius: "10px", overflow: "hidden" }}>
										{" "}
										<GatsbyImage
											image={bottomImage.childImageSharp.gatsbyImageData}
											className="w-100"
											alt={`Office space in ${popLocation.title}`}
										/>
									</div>
								</Col>
								<Col lg={8}>
									<h1>
										Office space in{" "}
										{popLocation.popularLocationsFields.locationName}
									</h1>
									{parse(popLocation.popularLocationsFields.description)}
								</Col>
							</Row>
						</Container>
					</section>
					<section
						className="bg-white position-relative mt-4"
						style={{ zIndex: 1 }}
					>
						<Container className="bg-white my-lg-6 my-3 my-md-5">
							<Row className="g-4 justify-content-center">
								<Col lg={12}>
									<div
										style={{
											boxShadow: "0px 1px 20px #00000029",
											borderRadius: "12px",
										}}
										className="py-3 py-md-5 px-3 px-md-5 mt-2  mb-3 bg-white "
									>
										<Row>
											<Col>
												<h2 className="mb-3 text-start">Get in touch</h2>
											</Col>
											<Row>
												<Col>
													<p className="mb-5 text-start">
														Fill in your contact details below and we'll put you
														in touch with one of our representatives
													</p>
												</Col>
											</Row>
										</Row>
										<ContactFormHome />
									</div>
									<ExpertCard />
								</Col>
							</Row>
						</Container>
					</section>
				</BasicLayout>
			</div>
		</>
	);
};

export default OfficesInBankPage;

export const pageQuery = graphql`
	query PopularLocationById($id: String!) {
		popLocation: wpAdPage(id: { eq: $id }) {
			seoFields {
				metaTitle
				metaDescription
				opengraphDescription
				opengraphTitle
				productSchema
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
						publicURL
					}
				}
			}
			title
			popularLocationsFields {
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 70
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				description
				latitude
				longitude
				locationName
				metaDescription
				property1
				property2
				property3
				property4
				property5
				url
			}
		}
		allAirtableProperties(filter: { live: { eq: true } }) {
			edges {
				node {
					addressLineOne
					desksFrom
					desksTo
					live
					city
					fixedId
					subwayStation1
					subwayStation1DistanceKm
					subwayStation1DurationMins
					subwayStation2
					subwayStation2DistanceKm
					subwayStation2DurationMins
					subwayStation3
					subwayStation3DistanceKm
					subwayStation3DurationMins
					type
					rentPM
					airtableId
					locationLatitude
					locationLongitude
					name
					provider

					features
					description
					photos
				}
			}
		}
		heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 70
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		paddington: wpMediaItem(title: { eq: "Bank" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		bottomImage1: file(relativePath: { eq: "Bank-Static-Image.jpg" }) {
			childImageSharp {
				original {
					height
					width
				}
				gatsbyImageData(
					formats: [AUTO, WEBP]
					quality: 70
					transformOptions: { cropFocus: CENTER, fit: CONTAIN }
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
		}

		canaryWharf: wpMediaItem(title: { eq: "London Bridge" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		piccadilly: wpMediaItem(title: { eq: "Mayfair" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}

		westminster: wpMediaItem(title: { eq: "Shoreditch-Static-Page-square" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
