import { ValidationError, useForm } from "@formspree/react";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import isBusinessEmail from "./is-business-email";

function ContactFormMorePeople({ formPropertyName }) {
	const [state, handleSubmit] = useForm("xpzeyokp");
	const [emailError, setEmailError] = useState("");
	const formGroupStyles = "mb-3 mb-xl-3";
	const formInputStyles = "p-xl-3 p-3 ps-xl-5 ps-3 border-0";

	const onSubmit = (event) => {
		event.preventDefault();

		const email = event.target.elements["email"].value;

		// Validate the email before submitting
		if (!isBusinessEmail(email)) {
			setEmailError("Please enter a valid business email address.");
			return; // Stop form submission if invalid
		}

		setEmailError(""); // Clear error if valid
		handleSubmit(event); // Submit the form if valid
	};

	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={onSubmit}>
					<Row>
						<Col lg={12}>
							<Form.Group className={`${formGroupStyles}`} controlId="name">
								<Form.Control
									required
									name="name"
									placeholder="Name"
									type="text"
									className={`${formInputStyles}`}
								/>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
						</Col>
						{/* <Col lg={6}>
					<Form.Group className={`${formGroupStyles}`} controlId="company">
						<Form.Control
							type="text"
							name="company"
							placeholder="Company"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="Company"
						field="company"
						errors={state.errors}
					/>
				</Col> */}
					</Row>

					<Form.Group className={`${formGroupStyles}`} controlId="email">
						<Form.Control
							required
							name="email"
							type="email"
							placeholder="Email Address"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
					<Form.Group className="mb-3" controlId="accept-the-policy">
						<Form.Check
							required
							label={
								<span>
									I accept the{" "}
									<a
										target="_blank"
										rel="noreferrer"
										href="https://app.termly.io/document/privacy-policy/d479506b-530d-4b78-afbf-949d899b4bd5"
									>
										privacy policy
									</a>
								</span>
							}
							className="w-auto"
							type="checkbox"
							name="accept-the-policy"
							value="true"
						/>
					</Form.Group>

					<ValidationError
						prefix="accept-the-policy"
						field="accept-the-policy"
						errors={state.errors}
					/>
					<div className="text-end">
						<Button
							size="small"
							className="btn btn-darker-blue text-white  px-6 rounded-pill w-100 w-md-auto"
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							SUBMIT
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={onSubmit}>
			<Row>
				<Col lg={12}>
					<Form.Group className={`${formGroupStyles}`} controlId="name">
						<Form.Control
							required
							name="name"
							placeholder="Name"
							type="text"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
				{/* <Col lg={6}>
					<Form.Group className={`${formGroupStyles}`} controlId="company">
						<Form.Control
							type="text"
							name="company"
							placeholder="Company"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="Company"
						field="company"
						errors={state.errors}
					/>
				</Col> */}
			</Row>

			<Form.Group className={`${formGroupStyles}`} controlId="email">
				<Form.Control
					required
					name="email"
					type="email"
					placeholder="Email Address"
					className={`${formInputStyles}`}
				/>
				{emailError && (
					<div style={{ color: "red", marginTop: "5px" }}>{emailError}</div>
				)}
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="accept-the-policy">
				<Form.Check
					required
					label={
						<span>
							I accept the{" "}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://app.termly.io/document/privacy-policy/d479506b-530d-4b78-afbf-949d899b4bd5"
							>
								privacy policy
							</a>
						</span>
					}
					className="w-auto"
					type="checkbox"
					name="accept-the-policy"
					value="true"
				/>
			</Form.Group>

			<ValidationError
				prefix="accept-the-policy"
				field="accept-the-policy"
				errors={state.errors}
			/>
			<div className="text-end">
				<Button
					size="small"
					className="btn btn-darker-blue text-white  px-6 rounded-pill w-100 w-md-auto"
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					SUBMIT
				</Button>
			</div>
		</Form>
	);
}
export default ContactFormMorePeople;
