import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import gsap from "gsap";
import React, { useEffect, useState } from "react";
import { Container, Offcanvas } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import { FaHeart, FaPhone, FaRegHeart } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import CartContext from "../context/CartContext";
import ShortlistWidget from "./shortlist-widget";
import Sticky from "react-stickynode";

import { WhatsAppWidget } from "react-whatsapp-widget";

import "react-whatsapp-widget/dist/index.css";
import FlexLogo from "./flex-logo";

// import { staticPopLocs } from "../utils/utils";

const BasicNavigation = ({ homepage, shortlistPage }) => {
	const data = useStaticQuery(graphql`
		query {
			whiteLogo: wpMediaItem(title: { eq: "Flex-Master-Logo-White" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			colorLogo: wpMediaItem(title: { eq: "Flex-MasterLogo-For-White-Bg" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whiteNoTextLogo: wpMediaItem(title: { eq: "Flex-Icon-White" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			colorNoTextLogo: wpMediaItem(title: { eq: "Flex Icon (For Navy Bg)" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpAreaGuide {
				nodes {
					title
					slug
				}
			}
		}
	`);

	const [Expanded, setExpanded] = useState(false);
	const [buttonBorder, setButtonBorder] = useState(false);

	const [selectedDropdown, setSelectedDropdown] = useState("");

	const handleMouseLeave = () => {
		setExpanded(false);
		setButtonBorder(false);

		gsap.to(".navbar-bottom", { scaleY: 0, duration: 0.5, delay: 0.5 });

		gsap.to(`.nav-bg`, {
			opacity: 0,
			duration: 0.1,
			delay: 1,
		});
	};

	const handleOtherClick = () => {
		setExpanded(false);
		gsap.to(
			".navbar-bottom",

			{ scaleY: 0, duration: 0.5, delay: 0.5 }
		);
	};

	const companyLogo = FlexLogo;

	const handleClick = (title) => {
		if (selectedDropdown === title && Expanded === true) {
			setExpanded(false);
			setButtonBorder(false);
			gsap.to(".navbar-bottom", { scaleY: 0, duration: 0.5, delay: 0.5 });
			gsap.to(`.nav-bg`, {
				opacity: 0,
				duration: 0.1,
				delay: 1,
			});
		} else if (selectedDropdown === title && Expanded === false) {
			setExpanded(true);
			setButtonBorder(true);
			gsap.to(`.nav-bg`, {
				opacity: 1,
				duration: 0.1,
				delay: 0.1,
			});
			gsap.fromTo(
				".navbar-bottom",

				{ scaleY: 0, duration: 0.5 },
				{ scaleY: 1, duration: 0.5, delay: 0.2 }
			);
		} else if (selectedDropdown !== title && Expanded === false) {
			setSelectedDropdown(title);

			setButtonBorder(true);
			setExpanded(true);
			gsap.to(`.nav-bg`, {
				opacity: 1,
				duration: 0.1,
				delay: 0.1,
			});
			gsap.fromTo(
				".navbar-bottom",

				{ scaleY: 0, duration: 0.5 },
				{ scaleY: 1, duration: 0.5 }
			);
		} else if (selectedDropdown !== title && Expanded === true) {
			setTimeout(function () {
				setSelectedDropdown(title);
			}, 550);

			gsap.to(".dropdown-content", {
				Webkitopacity: 0,
				opacity: 0,
				duration: 0.5,
			});
		}
	};

	// useEffect(() => {
	//   if (Expanded === true) {
	//     gsap.to(".dropdown-content", {
	//       opacity: 1,
	//       duration: 0.5,
	//       delay: 0.5,
	//     });
	//   }
	// }, [selectedDropdown]);
	useEffect(() => {
		if (Expanded === true) {
			gsap.to(".dropdown-content", {
				Webkitopacity: 1,
				opacity: 1,
				duration: 0.5,
				delay: 0.5,
			});
		}
	}, [selectedDropdown]);
	useEffect(() => {
		if (Expanded === false) {
			gsap.to(".dropdown-content", {
				Webkitopacity: 0,
				opacity: 0,
				duration: 0.5,
			});
		}
		if (Expanded === true) {
			gsap.to(".dropdown-content", {
				Webkitopacity: 1,
				opacity: 1,
				duration: 0.5,
				delay: 0.5,
			});
		}
	}, [Expanded]);

	useEffect(() => {
		gsap.to(".fade-in-cart", {
			opacity: 1,
			duration: 1,
			delay: 0.4,
		});
	}, []);

	const whiteLogo = data.whiteLogo?.localFile.childImageSharp.gatsbyImageData;
	const colorLogo = data.colorLogo?.localFile.childImageSharp.gatsbyImageData;
	const whiteNoTextLogo =
		data.whiteNoTextLogo?.localFile.childImageSharp.gatsbyImageData;
	const colorNoTextLogo =
		data.colorNoTextLogo?.localFile.childImageSharp.gatsbyImageData;

	const textColor = homepage ? "white" : "dark-blue";
	const heartTextColor = homepage ? "black" : "white";
	const logo = homepage ? whiteLogo : colorLogo;
	const offcanvasLogo = homepage ? whiteNoTextLogo : colorNoTextLogo;
	// const bgColor = homepage ? "primary" : "white";
	const bgColor = homepage ? "" : "white";
	const offcanvasColor = homepage ? "primary" : "white";
	const dropdownGradient = homepage
		? "dropdown-gradient-homepage"
		: "dropdown-gradient";
	// const sideColor = homepage ? "dark-blue" : "white";
	const navPosition = homepage ? "nav-position" : "";
	//   const [navPosition, setNavPosition] = useState(
	//     homepage ? "w-100 position-absolute start-50 translate-middle-x bg-t" : ""
	//   );

	const { cart, settingVal, onLocationChange } = React.useContext(CartContext);

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
			&#x25bc;
		</a>
	));

	// const fetchGeocode = async (locName) => {
	//   const accessToken =
	//     "pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw";
	//   const endpoint = "mapbox.places";
	//   const url = `https://api.mapbox.com/geocoding/v5/${endpoint}/${locName}.json?access_token=${accessToken}`;
	//   const response = await fetch(url);
	//   const data = await response.json();
	//   return await data.features[0].center;
	// };

	// const lat = async (locName) => {
	//   const locLat = await fetchGeocode(locName);
	//   return locLat[1];
	// };
	// const long = async (locName) => {
	//   const locLong = await fetchGeocode(locName);
	//   return locLong[0];
	// };

	const staticPopLocs = [
		{
			shortLocationName: "Bank",
			locationName: "Bank, London, UK",
			lat: 51.51757,
			long: -0.120609,
			link: "/offices-in-bank",
		},

		{
			shortLocationName: "Clerkenwell",
			locationName: "Clerkenwell, London, UK",
			lat: 51.523727,
			long: -0.105556,
			link: "/offices-in-clerkenwell",
		},

		{
			shortLocationName: "Covent Garden",
			locationName: "Covent Garden, London, UK",
			lat: 51.511736,
			long: -0.122677,
			link: "/offices-in-covent-garden",
		},

		{
			shortLocationName: "Kings Cross",
			locationName: "Kings Cross, London, UK",
			lat: 51.529108,
			long: -0.122413,
			link: "/offices-in-kings-cross",
		},

		{
			shortLocationName: "Knightsbridge",
			locationName: "Knightsbridge, London, UK",
			lat: 51.500844,
			long: -0.166965,
			link: "/offices-in-knightsbridge",
		},

		{
			shortLocationName: "London Bridge / Borough",
			locationName: "London Bridge / Borough, London, UK",
			lat: 51.496614,
			long: -0.0162935,
			link: "/offices-in-london-bridge",
		},

		{
			shortLocationName: "Marylebone",
			locationName: "Marylebone, London, UK",
			lat: 51.522059,
			long: -0.150455,
			link: "/offices-in-marylebone",
		},

		{
			shortLocationName: "Mayfair",
			locationName: "Mayfair, London, UK",
			lat: 51.511087,
			long: -0.147058,
			link: "/offices-in-mayfair",
		},

		{
			shortLocationName: "Midtown",
			locationName: "Midtown, London, UK",
			lat: 51.507322,
			long: -0.127647,
			link: "/offices-in-midtown",
		},

		{
			shortLocationName: "Paddington",
			locationName: "Paddington, London, UK",
			lat: 51.516305,
			long: -0.179242,
			link: "/offices-in-paddington",
		},

		{
			shortLocationName: "Shoreditch",
			locationName: "Shoreditch, London, UK",
			lat: 51.526669,
			long: -0.079893,
			link: "/offices-in-shoreditch",
		},

		{
			shortLocationName: "Soho",
			locationName: "Soho, London, UK",
			lat: 51.513163,
			long: -0.131175,
			link: "/offices-in-soho",
		},

		{
			shortLocationName: "St Pauls",
			locationName: "St Pauls, London, UK",
			lat: 51.513962,
			long: -0.10114,
			link: "/offices-in-st-pauls",
		},

		{
			shortLocationName: "Victoria",
			locationName: "Victoria, London, UK",
			lat: 51.495979,
			long: -0.141317,
			link: "/offices-in-victoria",
		},
	];

	const OnPopLocLinkClick = (loc) => {
		settingVal(loc.shortLocationName, "idLocation");
		handleClick();
	};
	const OnPopLocLinkClickNew = (loc) => {
		settingVal(loc, "idLocation");
		handleClick();
	};

	return (
		<div className="position-relative" style={{ zIndex: 99999 }}>
			{(cart?.length < 1 || cart === null) && (
				<div
					style={{ left: "0rem", bottom: "6rem", zIndex: 4 }}
					className="position-fixed widget d-lg-none"
				>
					<WhatsAppWidget
						companyName="Flex Office Solutions"
						replyTimeText="Get in touch"
						CompanyIcon={companyLogo}
						phoneNumber="+447934528624"
						message={"Hello! 👋🏼 \n\nHow can we help?"}
					/>
				</div>
			)}
			<div style={{ opacity: 0 }} className="fade-in-cart ">
				{cart !== null &&
					cart !== "undefined" &&
					cart?.length > 0 &&
					shortlistPage !== true && (
						<div
							style={{
								zIndex: 99999,
								width: "auto",
								// width: "400px",
							}}
							className="position-fixed   shortlist-widget end-0 bottom-0"
						>
							<ShortlistWidget />
							{/* <Accordion className="w-100" defaultActiveKey="-1">
							<Accordion.Item eventKey="0">
								<Accordion.Header>Shortlist</Accordion.Header>
								<Accordion.Body
									style={{ overflowY: "auto", maxHeight: "500px" }}
								>
									{cart.map((property, i) => (
										<ShortlistWidgetCard property={property} />
									))}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion> */}
						</div>
					)}
			</div>
			<Sticky>
				<div>
					<div
						onMouseLeave={() => handleMouseLeave()}
						className=" p-0"
						style={{ borderBottom: `1px solid rgba(108,108,108,.25)` }}
					>
						<div className={navPosition}>
							<Navbar
								bg={bgColor}
								variant={textColor}
								expand="xl"
								className={`py-2 py-lg-3 w-100 position-relative`}
								style={{ zIndex: 1 }}
							>
								<div
									style={{ zIndex: -999 }}
									className={`position-absolute ${
										homepage ? "nav-bg bg-primary" : ""
									}  w-100 h-100`}
								></div>

								<Container className={` bg=${bgColor} py-0 px-3 px-xl-0`}>
									<Navbar.Brand className="w-50 w-lg-20 me-lg-6 ">
										<GatsbyImage image={logo} alt={data.whiteLogo?.altText} />
									</Navbar.Brand>
								</Container>
							</Navbar>
						</div>
					</div>
				</div>
			</Sticky>
		</div>
	);
};

export default BasicNavigation;
