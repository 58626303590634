import Airtable from "airtable";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { validate } from "react-email-validator";
import { FaInstagram, FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import CartContext from "../context/CartContext";

// import { staticPopLocs, getRandomSubarray } from "../utils/utils";

const BasicFooter = () => {
	const data = useStaticQuery(graphql`
		query {
			flexLogo: wpMediaItem(title: { eq: "Flex-Icon-Navy" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);
	var base = new Airtable({
		apiKey: process.env.GATSBY_AIRTABLE_KEY,
	}).base("appiM1lTGCQd3Ru4A");
	const flexLogo = data.flexLogo?.localFile.childImageSharp.gatsbyImageData;
	const { settingVal, onLocationChange } = React.useContext(CartContext);
	const OnLocationLinkClick = (name, lat, long) => {
		settingVal(name, "locationValue");
		onLocationChange(lat, long);
	};
	const [showText, setShowText] = useState("");

	const HandleSubmit = (val) => {
		if (validate(val) === true && val !== "") {
			base("Emails")
				.select({
					filterByFormula: `{Email} = "${val}"`,
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function (record) {
							if (record.id) {
								setShowText("exists");
							}
						});
						if (records.length < 1) {
							setShowText("subscribed");
							base("Emails").create(
								{
									Email: val,
								},
								{ typecast: true },
								function (err, records) {
									if (err) {
										console.error(err);
										return;
									}
								}
							);
						}
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		} else {
			setShowText("invalid");
		}
	};

	const staticPopLocs = [
		{
			shortLocationName: "Bank",
			locationName: "Bank, London, UK",
			lat: 51.51757,
			long: -0.120609,
			link: "/offices-in-bank",
		},

		{
			shortLocationName: "Clerkenwell",
			locationName: "Clerkenwell, London, UK",
			lat: 51.523727,
			long: -0.105556,
			link: "/offices-in-clerkenwell",
		},

		{
			shortLocationName: "Covent Garden",
			locationName: "Covent Garden, London, UK",
			lat: 51.511736,
			long: -0.122677,
			link: "/offices-in-covent-garden",
		},

		{
			shortLocationName: "Kings Cross",
			locationName: "Kings Cross, London, UK",
			lat: 51.529108,
			long: -0.122413,
			link: "/offices-in-kings-cross",
		},

		{
			shortLocationName: "Knightsbridge",
			locationName: "Knightsbridge, London, UK",
			lat: 51.500844,
			long: -0.166965,
			link: "/offices-in-knightsbridge",
		},

		{
			shortLocationName: "London Bridge / Borough",
			locationName: "London Bridge / Borough, London, UK",
			lat: 51.496614,
			long: -0.0162935,
			link: "/offices-in-london-bridge",
		},

		{
			shortLocationName: "Marylebone",
			locationName: "Marylebone, London, UK",
			lat: 51.522059,
			long: -0.150455,
			link: "/offices-in-marylebone",
		},

		{
			shortLocationName: "Mayfair",
			locationName: "Mayfair, London, UK",
			lat: 51.511087,
			long: -0.147058,
			link: "/offices-in-mayfair",
		},

		{
			shortLocationName: "Midtown",
			locationName: "Midtown, London, UK",
			lat: 51.507322,
			long: -0.127647,
			link: "/offices-in-midtown",
		},

		{
			shortLocationName: "Paddington",
			locationName: "Paddington, London, UK",
			lat: 51.516305,
			long: -0.179242,
			link: "/offices-in-paddington",
		},

		{
			shortLocationName: "Shoreditch",
			locationName: "Shoreditch, London, UK",
			lat: 51.526669,
			long: -0.079893,
			link: "/offices-in-shoreditch",
		},

		{
			shortLocationName: "Soho",
			locationName: "Soho, London, UK",
			lat: 51.513163,
			long: -0.131175,
			link: "/offices-in-soho",
		},

		{
			shortLocationName: "St Pauls",
			locationName: "St Pauls, London, UK",
			lat: 51.513962,
			long: -0.10114,
			link: "/offices-in-st-pauls",
		},

		{
			shortLocationName: "Victoria",
			locationName: "Victoria, London, UK",
			lat: 51.495979,
			long: -0.141317,
			link: "/offices-in-victoria",
		},
	];

	return (
		<footer
			className="py-5 bg-darker-blue text-white position-relative"
			style={{ zIndex: 1 }}
		>
			<Container>
				<Row className="mx-3 mx-md-0">
					<Col xs={12} md={6} lg={4} className="mb-3">
						<div className="d-flex mb-2">
							<GatsbyImage
								image={flexLogo}
								style={{
									maxWidth: "50px",
									maxHeight: "50px",
								}}
								alt={data.flexLogo?.altText}
							/>

							<h2 className="align-self-center pt-2 ps-4">Flex Offices</h2>
						</div>
						<p>
							Simplify your office search with FLEX. With our free advisory
							service, you can trust us to quickly find you what you need.
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

const socialIcons = {
	color: "rgba(0, 0, 0, 0.5)",
};

const quickLinks = {};

export default BasicFooter;
