import React, { useEffect, useState } from "react";
import Footer from "./footer";
import NavigationOffcanvas from "./navigation-offcanvas";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import BasicNavigation from "./basic-nav";
import BasicFooter from "./basic-footer";

const BasicLayout = ({ children, homepage, shortlistPage }) => {
	return (
		<>
			<BasicNavigation />
			<link rel="stylesheet" href="https://use.typekit.net/vbn8hve.css" />
			<CookieBanner url="https://app.termly.io/document/cookie-policy/9f172afd-2481-4924-86d2-b632c14df3cc" />
			{children}
			<BasicFooter />
		</>
	);
};

export default BasicLayout;
